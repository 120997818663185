<template>
  <section class="mepandes" v-if="mepandes.length">
    <div
      class="heading text-center with-border mar-bottom-80"
      data-aos="fade-up"
    >
      <h2 class="title text-uppercase text-teal-dark">Mepandes</h2>
      <div class="sub-title">Potong Gigi</div>
    </div>

    <ul class="text-center persons px-2">
      <li
        class="person"
        data-aos="fade-up"
        v-for="person in mepandes"
        :key="person.name"
      >
        <!-- <img src="@/assets/icons/arrow.svg" /> -->
        <img :src="person.photo" class="image" />
        <div class="name font-secondary px-3">{{ person.name }}</div>
        <!-- <img src="@/assets/icons/arrow.svg" class="flip-horinzontal" /> -->
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: ["mepandes"],
};
</script>

<style lang="scss" scoped>
.mepandes {
  .person {
    display: block;
    text-align: center;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 0.5rem;
  }
  .image {
    width: 100%;
    max-width: 175px;
    opacity: 1;
    border-radius: .5rem;
    margin-bottom: 1rem;
  }

  .name {
    text-align: center;
    font-weight: bold;
    flex-grow: 1;
    font-size: 1.5rem;
  }

  @media (min-width: 768px) {
    .persons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .person {
        width: 33%;
        margin-left: 2%;
        margin-right: 2%;
        display: block;
        padding: 0;
        overflow: hidden;
        margin-bottom: 2rem;

        .image {
          width: 100%;
          margin: 0;
        }

        .name {
          text-align: center;
          padding: 1rem 1.5rem;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .persons {
      .person {
        width: 25%;
        max-width: 300px;
      }
    }
  }
}
</style>
